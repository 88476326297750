var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(this.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "time-slot-form",
            submitAction: _vm.create,
            submitSuccessAction: _vm.createSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "name", placeholder: _vm.$t("shared.name") },
                model: {
                  value: _vm.data.name,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "name", $$v)
                  },
                  expression: "data.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-timepicker", {
                attrs: {
                  id: "startTime",
                  placeholder: _vm.$t("shared.startTime")
                },
                model: {
                  value: _vm.data.startTime,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "startTime", $$v)
                  },
                  expression: "data.startTime"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-timepicker", {
                attrs: { id: "endTime", placeholder: _vm.$t("shared.endTime") },
                model: {
                  value: _vm.data.endTime,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "endTime", $$v)
                  },
                  expression: "data.endTime"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-numerictextbox", {
                attrs: {
                  id: "order",
                  format: "N0",
                  placeholder: _vm.$t("shared.order")
                },
                model: {
                  value: _vm.data.order,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "order", $$v)
                  },
                  expression: "data.order"
                }
              })
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.create",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }